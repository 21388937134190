import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';
import { TranslateService } from '@ngx-translate/core';
import { NbMenuItem } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

	count=0;
	list: NbMenuItem[]=[];
  	constructor(
  		private httpService: HttpService,
        private translate: TranslateService
  	) { }

  	//更新通知資訊
  	update() {
  		let url = `${environment.APIUrl}/api/lab-member-center/admin/notify-statistic`;

  		this.httpService.getHttp(url).subscribe(
  			res => {
  				this.count = res.sum;
  				this.list = [];

  				res.items.forEach(item =>{

                    let list_item = {
                        title: ''
                    };

                    list_item['title'] = `${this.translate.instant('notify.'+item.type)} (${item.value})`;

  					switch (item.type) {
  						case "order_pending_status":
  							list_item['link'] = '/shop/order';
                            list_item['queryParams'] = {order_status: 'PENDING'};
  							break;

                        case "return_order_not_closed_status":
                            list_item['link'] = '/shop/order';
                            list_item['queryParams'] = {stage_type: 'return', close_status:0};
                            break;

                        case "general_order_not_closed_status":
                            list_item['link'] = '/shop/order';
                            list_item['queryParams'] = {stage_type: 'general', close_status:0};
                            break;
  						
  						default:
  							// code...
  							break;
  					}
                    this.list.push(list_item);
  				});
                //console.warn(this.list)
  			}
  		);
  	}
}

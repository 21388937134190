import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import { NGXLogger } from "ngx-logger";
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  	constructor(
  		private tokenService:TokenService,
  		private httpService:HttpService,
  		private router:Router, 
  		private logger: NGXLogger,
  		private cookieService: CookieService
  	) { }


  	//已登入導向指定頁面
  	loginRedirct(path) {
		this.tokenService.infoState()
			.subscribe(res =>{
				if(res=='SUCCESS'){
					//this.logger.debug(res);
					this.router.navigate([path]);
				}
			});
	}


  	//進行登入 儲存資料
  	login(data) {
  		this.tokenService.setToken(data.token);
  		localStorage.setItem('user_info', JSON.stringify(data.user_info));
  	}


  	//取得目前登入的使用者資料
  	getUserInfo() {
  		let user_info = localStorage.getItem('user_info');
  		return JSON.parse(user_info);
  	}


  	//登出
  	logout(path) {
  		let url = `${environment.APIUrl}/api/lab-auth/logout`;

  		this.httpService.postHttp(url,null).subscribe(
  			res => {
          		localStorage.clear();
          		this.cookieService.clearCookies();
  				this.router.navigate([path]);
  			}
  		);
  	}
}

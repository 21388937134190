import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';

import { AdminHomeComponent } from './layout/admin-home/admin-home.component';

const routes: Routes = [

	//系統首頁
	{ 	
		path: '', 
		component: AdminHomeComponent,
		canActivate:[AdminGuard],
		canActivateChild: [AdminGuard],
		//data: { permission:'home' },
		children: [
			//商城系統
			{ 
				path: 'shop', 
				loadChildren: () => import('./shop-admin/shop-admin.module').then(m => m.ShopAdminModule) 
			},
			//內容管理系統系統
			{ 
				path: 'content', 
				loadChildren: () => import('./content-admin/content-admin.module').then(m => m.ContentAdminModule) 
			},
			//會員管理系統系統
			{ 
				path: 'member', 
				loadChildren: () => import('./member-admin/member-admin.module').then(m => m.MemberAdminModule) 
			},
			//管理員
			{ 
				path: 'admin', 
				loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
			},
			//逗寶外部訂單
			{ 
				path: 'ex-order', 
				loadChildren: () => import('./ex-order/ex-order.module').then(m => m.ExOrderModule) 
			},
			//逗寶活動
			{ 
				path: 'activity', 
				loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule) 
			},
		]

	},

	//驗證頁面
	{ 
		path: 'auth', 
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) 
	}
	
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

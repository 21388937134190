// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  	production: false,
  	debugger: true,
  	default_shop_id: '1',
  	default_shop_name: '人本開發區後台',
  	APIUrl: "https://hef-api.labspace.com.tw",
	APIUrlReg: "^https:\/\/.*labspace.com.tw",
	FileUrl: "https://hef-api.labspace.com.tw/storage",
	paymentUrl: "https://hef-api.labspace.com.tw/api/lab-shop/front/go-pay",
	ExportUrl: "https://hef-api.labspace.com.tw/storage",
	Frontstage: "https://href.labspace.com.tw",
	maxLevel: {
		"noteCategory": 1,
		"publishCategory": 1,
		"campCategory": 2,
		"product_option": 1
	},
	langs: ['zh-tw'],
	defaultLang: 'zh-tw',
	allLangRequired: false,
	recaptchSiteKey: '6LfeoHgUAAAAAHAf7STNiJyGliWQStxlOZTgq-YL',
	smsTimeout: 60,
	loginTimeout: 0,
	GA: {
		trackingID: 'UA-123182296-24',
		refund: true
	},
	version: '20230613',
	ckEditorConfig: {
		//viewportTopOffset : 76,
		toolbar: {
			items: [
				'htmlEmbed','heading', '|',
		        'alignment', '|',
		        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
		        'link', 'anchor', '|',
		        'bulletedList', 'numberedList','|',
		        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
		        'code', '|',
		        'insertTable', '|',
		        'outdent', 'indent', '|',
		        'uploadImage', 'blockQuote', 'mediaEmbed','|',
		        'undo', 'redo', 'horizontalLine'
			]
		},
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { 
  NbThemeModule, 
  NbLayoutModule, 
  NbSidebarModule,
  NbContextMenuModule, 
  NbMenuModule, 
  NbIconModule,
  NbDialogModule,
  NbTooltipModule,
  NbDatepickerModule,
  NbActionsModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
//import { NbMomentDateModule } from '@nebular/moment';

//http client & interceptor
import { HttpClientModule,  HTTP_INTERCEPTORS } from '@angular/common/http';

//loading
import { NgxSpinnerModule } from "ngx-spinner";

//Interceptor
import { RequestInterceptor } from '../app/interceptors/request.interceptor';
import { ResponseInterceptor } from '../app/interceptors/response.interceptor';
import { TokenRefreshInterceptor } from '../app/interceptors/tokenRefresh.interceptor';
import { ErrorNotifierInterceptor } from '../app/interceptors/errorNotifier.interceptor';

//sweetalert2
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

//log
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

//gobal var
import { environment } from '../environments/environment';
import { FileLibModule } from '@fresh08man/file-lib';

//form
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//多語系
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

//google recaptch
import { RecaptchaModule } from 'ng-recaptcha';

//component
import { AdminHomeComponent } from './layout/admin-home/admin-home.component';
import { AdminMenuComponent } from './layout/admin-menu/admin-menu.component';



/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenRefreshInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorNotifierInterceptor, multi: true },
];

// 建立TranslateHttpLoader作為語系檔的讀取器
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}


@NgModule({
  declarations: [
    AppComponent,
    AdminHomeComponent,
    AdminMenuComponent,
  ],
  imports: [
    FileLibModule.forRoot(environment),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbIconModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTooltipModule,
    HttpClientModule,
    NgxSpinnerModule,
    NbContextMenuModule,
    NbActionsModule,
    //NbMomentDateModule,
    SweetAlert2Module.forRoot(),  
    LoggerModule.forRoot({
        serverLoggingUrl: '/api/logs', 
        //level: NgxLoggerLevel.DEBUG, 
        level: environment.debugger ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.ERROR,
        serverLogLevel: NgxLoggerLevel.ERROR
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    RecaptchaModule
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { NbSidebarService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { NotifyService } from '../../services/notify.service';
import { NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import { SmsService } from '../../services/sms.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

	@HostListener('document:mousemove', ['$event']) 
  	onMouseMove(e) {
    	//console.log(e);
    	if(environment.loginTimeout!=0){
    		if(this.timeSubscription){
	    		this.timeSubscription.unsubscribe();
	    	}
	    	this.loginTimeout(environment.loginTimeout);
    	}
    	
  	}

	user_info;
	version = environment.version;
	title = environment.default_shop_name;

	shop = {
		id:localStorage.getItem('shop_id'),
		name:localStorage.getItem('shop_name')
	};

	timeout;
	timeSubscription;

  	constructor(
  		private sidebarService: NbSidebarService,
  		public authService: AuthService,
        public notifyService: NotifyService,
        private nbMenuService: NbMenuService, 
        private router: Router,
        private smsService: SmsService
  	) { }

  	ngOnInit(): void {

  		//計時
  		this.loginTimeout(environment.loginTimeout);

  		this.user_info = this.authService.getUserInfo();

        //設定預設商店名稱
        if(!this.shop.name){
            this.shop.id = environment.default_shop_id;
            this.shop.name = environment.default_shop_name;
        }

        //通知超連結
        /*
        this.nbMenuService.onItemClick()
            .pipe(
                filter(({ tag }) => tag === 'notify-context-menu'),
                map(({ item: { link } }) => link),
            )
            .subscribe(link => {
                this.router.navigate([link]);
            });*/
  	}



  	ngAfterViewInit(){
  		//this.sslSeal();
  	}


  	//左邊sidebar 開合
  	toggle() {
    	this.sidebarService.toggle();
  	}


  	//多久後登出
  	loginTimeout(second){
  		if(second==0){
  			return;
  		}

  		this.timeSubscription = this.smsService.startTimer(second).subscribe(res => {
		  	this.timeout = second - res;
		  	//console.log(this.timeout);
		  	if(this.timeout<=0){
		  		this.authService.logout('auth/admin/login');
		  	}
		});
  	}

  	//動態ssl標章
    sslSeal() {
    	//if(isPlatformBrowser(this.platformId)){
	    	const jsElm1 = document.createElement('script');
		    jsElm1.type = 'text/javascript';
		    jsElm1.charset = 'utf-8';
		    jsElm1.text = 'var twca_cn="www.dollbao.com.tw";';

		    const jsElm2 = document.createElement('script');
		    jsElm2.type = 'text/javascript';
		    jsElm2.charset = 'utf-8';
		    jsElm2.src = '//ssllogo.twca.com.tw/twcaseal_v3.js';

		    document.body.appendChild(jsElm1);
		    document.body.appendChild(jsElm2);
		//}
    }
  	

}

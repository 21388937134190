let defaultHidden = true;
let defaultExpand = false;

export const Menus: any = [
        {
            title: 'home',
            icon: 'home-outline',
            data : 'home',
            link: '/',
            hidden: defaultHidden
        },

        {
            title: 'shop',
            icon: 'home-outline',
            data : 'home-shop',
            link: '/shop/select',
            hidden: defaultHidden
        },



        //行銷管理
        /*
    	{
    	  	title: 'discount',
            icon: 'shopping-cart-outline',
            data : 'home-discount',
    	  	expanded: defaultExpand,
            hidden: defaultHidden,
    	  	children: [
                {
                    title: 'shopDiscountList',
                    data: 'home-discount-shopDiscountList',
                    link: '/shop/shop-discount',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'productDiscountGroup',
                    data: 'home-discount-productDiscountGroup',
                    link: '/shop/discount-group',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'productDiscountList',
                    data: 'home-discount-productDiscountGroup-productDiscountList',
                    link: '/shop/product-discount',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'discountExcludeList',
                    data: 'home-discount-discountExcludeList',
                    link: '/shop/discount-exclude',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'discountCode',
                    data: 'home-discount-discountCode',
                    link: '/shop/coupon/code',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                }

    	  	],
    	},*/

    	//人本教育札記

    	{
            title: 'note',
            icon: 'shopping-bag-outline',
            data : 'home-note',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	{
                    title: 'noteTag',
                    icon: 'grid-outline',
                    data: 'home-note-noteTag',
                    link: '/shop/note/tag',
                    hidden: defaultHidden,
              },
              {
                    title: 'noteMedia',
                    icon: 'grid-outline',
                    data: 'home-note-noteMedia',
                    link: '/shop/note/media',
                    hidden: defaultHidden,
              },
                {
                    title: 'noteCategory',
                    icon: 'grid-outline',
                    data: 'home-note-noteCategory',
                    link: '/shop/note/category',
                    hidden: defaultHidden,
              },
              {
                    title: 'noteList',
                    icon: 'grid-outline',
                    data: 'home-note-noteList',
                    link: '/shop/note/list',
                    hidden: defaultHidden,
            },
              {
                    title: 'expertList',
                    icon: 'grid-outline',
                    data: 'home-note-expertList',
                    link: '/shop/note/expertList',
                    hidden: defaultHidden,
            },
            {
                title: 'serialColumn',
                icon: 'grid-outline',
                data: 'home-note-noteCategory',
                link: '/shop/note/serialColumn',
                hidden: defaultHidden,
          },
            {
                title: 'column',
                icon: 'grid-outline',
                data: 'home-note-noteCategory',
                link: '/shop/note/column',
                hidden: defaultHidden,
          },
              //   {
              //       title: 'noteList',
              //       data: 'home-note-noteList',
              //       link: '/shop/note/product',
              //       queryParams: {sort_field:'created_at', sort_type:'desc'},
              //       icon: 'shopping-bag-outline',
              //       hidden: defaultHidden,
              //   },
              //   {
              //       title: 'noteSku',
              //       data: 'home-note-noteSku',
              //       link: '/shop/note/sku',
              //       icon: 'pricetags-outline',
              //       hidden: defaultHidden,
              //   }
              {
                    title: 'notePaperVolumn',
                    icon: 'grid-outline',
                    data: 'home-note-notePaperVolumn',
                    link: '/shop/note/paper/volumn',
                    hidden: defaultHidden,
                },
                {
                    title: 'noteHomeBanner',
                    icon: 'grid-outline',
                    data: 'home-note-noteHomeBanner',
                    link: '/shop/note/banner/note_banner',
                    hidden: defaultHidden,
                },
                {
                    title: 'noteHomeMBanner',
                    icon: 'grid-outline',
                    data: 'home-note-noteHomeMBanner',
                    link: '/shop/note/banner/note_m_banner',
                    hidden: defaultHidden,
                },
                {
                    title: 'noteMediaBanner',
                    icon: 'grid-outline',
                    data: 'home-note-noteMediaBanner',
                    link: '/shop/note/banner/note_media_banner',
                    hidden: defaultHidden,
                },
                // {
                //     title: 'noteList',
                //     data: 'home-note-noteList',
                //     link: '/shop/note/product',
                //     queryParams: {sort_field:'created_at', sort_type:'desc'},
                //     icon: 'shopping-bag-outline',
                //     hidden: defaultHidden,
                // },
                // {
                //     title: 'noteSku',
                //     data: 'home-note-noteSku',
                //     link: '/shop/note/sku',
                //     icon: 'pricetags-outline',
                //     hidden: defaultHidden,
                // }
            ]
        },

   		//出版品

    	{
            title: 'publish',
            icon: 'shopping-bag-outline',
            data : 'home-publish',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	{
                    title: 'publishCategory',
                    icon: 'grid-outline',
                    data: 'home-publish-publishCategory',
                    link: '/shop/publish/category',
                    hidden: defaultHidden,
                },
                {
                  title: 'publishKeywordLink',
                  icon: 'browser-outline',
                  data: 'home-publish-publishKeywordLink',
                  link: '/shop/publish/keyword/publish-hot-keyword',
                  hidden: defaultHidden,
                },
                {
                  title: 'publishBanner',
                  icon: 'image-outline',
                  data: 'home-publish-publishBanner',
                  link: '/shop/publish/banner/publish-media',
                  hidden: defaultHidden,
                },
                {
                    title: 'publishList',
                    data: 'home-publish-publishList',
                    link: '/shop/publish/product',
                    queryParams: {sort_field:'created_at', sort_type:'desc'},
                    icon: 'shopping-bag-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'publishSku',
                    data: 'home-publish-noteSku',
                    link: '/shop/publish/sku',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                }
            ]
        },

        //活動營隊
    	{
            title: 'camp',
            icon: 'shopping-bag-outline',
            data : 'home-camp',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	{
                    title: 'camp_banner',
                    icon: 'grid-outline',
                    data: 'home-camp-camp_banner',
                    link: '/content/camp/banner',
                    hidden: defaultHidden,
                },
            	{
                    title: 'campCategory',
                    icon: 'grid-outline',
                    data: 'home-camp-campCategory',
                    link: '/shop/camp/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'campList',
                    data: 'home-camp-campList',
                    link: '/shop/camp/product',
                    queryParams: {sort_field:'created_at', sort_type:'desc'},
                    icon: 'shopping-bag-outline',
                    hidden: defaultHidden,
                }

            ]
        },

        //數學想想
    	{
            title: 'math',
            icon: 'shopping-bag-outline',
            data : 'home-math',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	{
                    title: 'math_banner',
                    icon: 'grid-outline',
                    data: 'home-math-math_banner',
                    link: '/content/math/banner',
                    hidden: defaultHidden,
                },
            	{
                    title: 'mathCategory',
                    icon: 'grid-outline',
                    data: 'home-math-mathCategory',
                    link: '/shop/math/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'mathList',
                    data: 'home-math-mathList',
                    link: '/shop/math/product',
                    queryParams: {sort_field:'created_at', sort_type:'desc'},
                    icon: 'shopping-bag-outline',
                    hidden: defaultHidden,
                }

            ]
        },

        //父母學苑
    	{
            title: 'parent',
            icon: 'shopping-bag-outline',
            data : 'home-parent',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	{
                    title: 'parent_banner',
                    icon: 'grid-outline',
                    data: 'home-parent-parent_banner',
                    link: '/content/parent/banner',
                    hidden: defaultHidden,
                },
            	{
                    title: 'parentCategory',
                    icon: 'grid-outline',
                    data: 'home-parent-parentCategory',
                    link: '/shop/parent/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'parentList',
                    data: 'home-parent-parentList',
                    link: '/shop/parent/product',
                    queryParams: {sort_field:'created_at', sort_type:'desc'},
                    icon: 'shopping-bag-outline',
                    hidden: defaultHidden,
                }

            ]
        },

        //人本教育學院
    	{
            title: 'college',
            icon: 'shopping-bag-outline',
            data : 'home-college',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	{
                    title: 'college_banner',
                    icon: 'grid-outline',
                    data: 'home-college-college_banner',
                    link: '/content/college/banner',
                    hidden: defaultHidden,
                },
            	{
                    title: 'collegeCategory',
                    icon: 'grid-outline',
                    data: 'home-college-collegeCategory',
                    link: '/shop/college/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'collegeList',
                    data: 'home-college-collegeList',
                    link: '/shop/college/product',
                    queryParams: {sort_field:'created_at', sort_type:'desc'},
                    icon: 'shopping-bag-outline',
                    hidden: defaultHidden,
                }

            ]
        },

        //訂閱方案管理
        {
            title: 'subscribe',
            icon: 'file-text-outline',
            data: 'home-subscribe',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'subscribeList',
                    data: 'home-subscribe-subscribeList',
                    link: '/shop/subscribe',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
            ]
        },



        ////訂單管理
        {
            title: 'order',
            icon: 'file-text-outline',
            data : 'home-order',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'orderList',
                    data: 'home-order-orderList',
                    link: '/shop/order',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                  title: 'orderProductList',
                  data: 'home-order-orderProductList',
                  link: '/shop/order',
                  queryParams: {type:'product'},
                  icon: 'pricetags-outline',
                  hidden: defaultHidden,
                },
                {
                    title: 'orderEventList',
                    data: 'home-order-orderEventList',
                    link: '/shop/order',
                    queryParams: {type:'event'},
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'orderLargeList',
                    data: 'home-order-orderLargeList',
                    link: '/shop/order',
                    queryParams: {type:'large'},
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'orderWaitingList',
                    data: 'home-order-orderWaitingList',
                    link: '/shop/order',
                    queryParams: {type:'waiting'},
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },

                {
                    title: 'orderReturnList',
                    data: 'home-order-orderReturnList',
                    link: '/shop/order-return',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },


                /*
                {
                    title: 'orderReturnList',
                    data: 'home-order-orderList',
                    link: '/shop/order',
                    queryParams: {stage_type:'return'},
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'orderGeneralList',
                    data: 'home-order-orderList',
                    link: '/shop/order',
                    queryParams: {stage_type:'general'},
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'orderCancelList',
                    data: 'home-order-orderList',
                    link: '/shop/order',
                    queryParams: {stage_type:'cancel'},
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'orderCloseList',
                    data: 'home-order-orderList',
                    link: '/shop/order',
                    queryParams: {close_status:1},
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'exOrderList',
                    data: 'home-order-exOrderList',
                    link: '/ex-order/order',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },

                {
                    title: 'shippingList',
                    data: 'home-order-shippingList',
                    link: '/shop/shipping',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
			*/
                {
                    title: 'paymentList',
                    data: 'home-order-paymentList',
                    link: '/shop/payment',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },

                /*
                {
                    title: 'returnOption',
                    data: 'home-order-returnOption',
                    link: '/content/option/return',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'cancelOption',
                    data: 'home-order-cancelOption',
                    link: '/content/option/cancel',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },

                {
                    title: 'shippingCompanyList',
                    data: 'home-order-shippingCompanyList',
                    link: '/shop/shipping-company',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                */
            ]
        },


        //捐款
        {
            title: 'projectList',
            icon: 'layout-outline',
            data : 'home-donate-projectList',
            link: '/shop/donate/project',
            hidden: defaultHidden,
        },

        //捐款紀錄
        {
            title: 'donateRecord',
            icon: 'layout-outline',
            data : 'home-donateRecord',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                	title: 'donateRecordAll',
                    data: 'home-donateRecord-donateRecordAll',
                    link: '/shop/donate-record/all',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                	title: 'donateRecordOnce',
                    data: 'home-donateRecord-donateRecordOnce',
                    link: '/shop/donate-record/once',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                	title: 'donateRecordSubscription',
                    data: 'home-donateRecord-donateRecordSubscription',
                    link: '/shop/donate-record/subscription',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                }
            ]
        },

        //收據管理
        {
            title: 'donateRecipt',
            icon: 'layout-outline',
            data : 'home-donateRecipt',
            link: '/shop/recipt',
            hidden: defaultHidden,
        },

        //紀念品管理
        {
            title: 'donateSouvenir',
            icon: 'layout-outline',
            data : 'home-donateSouvenir',
            link: '/shop/donate-souvenir',
            hidden: defaultHidden,
        },


 		//最新消息
 		/*
        {
            title: 'news',
            icon: 'layout-outline',
            data : 'home-news',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'newsCategory',
                    icon: 'image-outline',
                    data: 'home-news-newsCategory',
                    link: '/content/news/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'newsList',
                    data: 'home-news-newsList',
                    link: '/content/news',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                }
            ]
        },

        //評論
        {
            title: 'review',
            icon: 'layout-outline',
            data : 'home-review',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'reviewCategory',
                    icon: 'image-outline',
                    data: 'home-review-reviewCategory',
                    link: '/content/review/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'reviewList',
                    data: 'home-review-reviewList',
                    link: '/content/review',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                }
            ]
        },

        //育兒知識
        {
            title: 'article',
            icon: 'layout-outline',
            data : 'home-article',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	{
                    title: 'articleCategory',
                    icon: 'grid-outline',
                    data: 'home-article-articleCategory',
                    link: '/content/article/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'articleList',
                    icon: 'message-square-outline',
                    data: 'home-article-articleList',
                    link: '/content/article',
                    hidden: defaultHidden,
                }
            ]
        },

        //門市管理
        {
            title: 'store',
            icon: 'layout-outline',
            data : 'home-store',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'storeCategoryList',
                    icon: 'image-outline',
                    data: 'home-store-storeCategoryList',
                    link: '/content/store/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'storeList',
                    data: 'home-store-storeList',
                    link: '/content/store',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                }
            ]
        },
*/

        //客服管理

        {
            title: 'customerService',
            icon: 'layout-outline',
            data : 'home-customerService',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [

            	/*
            	{
                    title: 'contactCategory',
                    icon: 'browser-outline',
                    data: 'home-customerService-contactCategory',
                    link: '/content/contact-category',
                    hidden: defaultHidden,
                },
                {
                    title: 'contactList',
                    icon: 'browser-outline',
                    data: 'home-customerService-contactList',
                    link: '/content/contact',
                    hidden: defaultHidden,
                },


                //購物常見問題
                {
                    title: 'qaCategory',
                    icon: 'image-outline',
                    data: 'home-customerService-qaCategory',
                    link: '/content/qa/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'qaList',
                    data: 'home-customerService-qaList',
                    link: '/content/qa',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                },*/

                //站內信
                {
                    title: 'mailList',
                    data: 'home-customerService-mailList',
                    link: '/member/mail',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                },

                {
                    title: 'smsList',
                    data: 'home-customerService-smsList',
                    link: '/member/sms',
                    icon: 'message-square-outline',
                    queryParams: {sort_field:'created_at', sort_type:'desc'},
                    hidden: defaultHidden,
                },

                {
                    title: 'subscribeNotify',
                    data: 'home-customerService-subscribeNotify',
                    link: '/member/subscribeNotify',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                },

            ]
        },

        //固定克制頁面們
        {
            title: 'page',
            icon: 'layout-outline',
            data : 'home-page',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'pagePrivacy',
                    icon: 'browser-outline',
                    data: 'home-page-pagePrivacy',
                    link: '/content/page/fixed/privacy',
                    hidden: defaultHidden,
                },
                {
                    title: 'pagePIP',
                    icon: 'browser-outline',
                    data: 'home-page-pagePIP',
                    link: '/content/page/fixed/personal-info-protection',
                    hidden: defaultHidden,
                },

            ],
        },

        /*
        //公司管理
        {
            title: 'about',
            icon: 'layout-outline',
            data : 'home-about',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'pageList',
                    icon: 'browser-outline',
                    data: 'home-about-pageList',
                    link: '/content/about',
                    hidden: defaultHidden,
                },

                {
                    title: 'mediaCategory',
                    icon: 'browser-outline',
                    data: 'home-about-mediaCategory',
                    link: '/content/media/category',
                    hidden: defaultHidden,
                },

                {
                    title: 'mediaList',
                    data: 'home-about-mediaCategory-mediaList',
                    link: '/content/media',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                }

            ],
        },*/

        //廣告
        {
            title: 'recommend',
            icon: 'layout-outline',
            data : 'home-recommend',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	/*
             	{
                    title: 'recommendParameter',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendParameter',
                    link: '/admin/parameter_home',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendCover',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendCover',
                    link: '/content/recommend/cover',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendHeader',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendHeader',
                    link: '/content/recommend/header',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendSpecial',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendSpecial',
                    link: '/content/recommend/special',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendHome',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendHome',
                    link: '/content/recommend/home',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendStartBanner',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendStartBanner',
                    link: '/content/recommend/star-banner',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendBottom',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendBottom',
                    link: '/content/recommend/bottom',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendFooter',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendFooter',
                    link: '/content/recommend/footer',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendLeftCover',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendLeftCover',
                    link: '/content/recommend/left_cover',
                    hidden: defaultHidden,
                },
                */
                {
                    title: 'home_top',
                    icon: 'image-outline',
                    data: 'home-recommend-home_top',
                    link: '/content/recommend/home-top',
                    hidden: defaultHidden,
                },

                {
                    title: 'banner',
                    icon: 'image-outline',
                    data: 'home-recommend-banner',
                    link: '/content/banner/home-top',
                    hidden: defaultHidden,
                },
                /*
                {
                    title: 'homeLink',
                    icon: 'browser-outline',
                    data: 'home-recommend-homeLink',
                    link: '/content/homelink/top',
                    hidden: defaultHidden,
                },
                */
                {
                    title: 'keywordLink',
                    icon: 'browser-outline',
                    data: 'home-recommend-keywordLink',
                    link: '/content/keyword/hot-keyword',
                    hidden: defaultHidden,
                },
                /*
                {
                    title: 'footer',
                    icon: 'browser-outline',
                    data: 'home-recommend-footer',
                    link: '/content/footer/f-about',
                    hidden: defaultHidden,
                },
                {
                    title: 'live',
                    icon: 'browser-outline',
                    data: 'home-recommend-live',
                    link: '/content/live/live',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendMarquee',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendMarquee',
                    link: '/content/recommend/marquee',
                    hidden: defaultHidden,
                },

                {
                    title: 'newProduct',
                    icon: 'browser-outline',
                    data: 'home-recommend-newProduct',
                    link: '/shop/recommend/product/new',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendTopic',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendTopic',
                    link: '/content/recommend/group/topic',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendVideo',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendVideo',
                    link: '/content/recommend/group/video',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendStar',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendStar',
                    link: '/content/recommend/star',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendClassic',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendClassic',
                    link: '/content/recommend/classic',
                    hidden: defaultHidden,
                },
                */

                {
                    title: 'frame',
                    icon: 'browser-outline',
                    data: 'home-recommend-frame',
                    link: '/content/frame',
                    hidden: defaultHidden,
                }

            ],
        },


   		//會員
    	{
    	  	title: 'member',
            icon: 'person-outline',
            data: 'home-member',
    	  	hidden: defaultHidden,
            expanded: defaultExpand,
            children: [
                {
                    title: 'memberList',
                    icon: 'list',
                    data: 'home-member-memberList',
                    link: '/member',
                    hidden: defaultHidden,
                },
                {
                    title: 'familyList',
                    icon: 'list',
                    data: 'home-member-familyList',
                    link: '/member/family',
                    hidden: defaultHidden,
                },

                /*
                {
                    title: 'memberLevelList',
                    icon: 'list',
                    data: 'home-member-memberLevelList',
                    link: '/member/level',
                    hidden: defaultHidden,
                },
                {
                    title: 'daoballLevelList',
                    icon: 'list',
                    data: 'home-member-daoballLevelList',
                    link: '/member/daoball-level',
                    hidden: defaultHidden,
                },
                {
                    title: 'zyoLevelNote',
                    icon: 'list',
                    data: 'home-member-zyoLevelNote',
                    link: '/ex-order/zyo/level/note',
                    hidden: defaultHidden,
                },
                {
                    title: 'memberTagList',
                    icon: 'list',
                    data: 'home-member-memberTagList',
                    link: '/member/tag',
                    hidden: defaultHidden,
                },
                {
                    title: 'memberCRMTagList',
                    icon: 'list',
                    data: 'home-member-memberCRMTagList',
                    link: '/member/crm-tag',
                    hidden: defaultHidden,
                },
                {
                    title: 'consumptionList',
                    icon: 'list',
                    data: 'home-member-consumptionList',
                    link: '/member/consumption',
                    hidden: defaultHidden,
                },
                {
                    title: 'deliveryList',
                    icon: 'list',
                    data: 'home-member-deliveryList',
                    link: '/shop/delivery',
                    hidden: defaultHidden,
                },
                {
                    title: 'epaperList',
                    icon: 'list',
                    data: 'home-member-epaperList',
                    link: '/member/epaper',
                    hidden: defaultHidden,
                },
                */

            ]
    	},


    	//會員優惠

        {
            title: 'account',
            icon: 'person-outline',
            data: 'home-account',
            hidden: defaultHidden,
            expanded: defaultExpand,
            children: [
                {
                    title: 'accountList',
                    icon: 'list',
                    data: 'home-account-accountList',
                    link: '/member/account',
                    hidden: defaultHidden,
                },
                /*
                {
                    title: 'dollbaoFeedback',
                    icon: 'list',
                    data: 'home-account-dollbaoFeedback',
                    link: '/member/account-feedback/dollbao',
                    hidden: defaultHidden,
                },
                {
                    title: 'dollbaoTransfer',
                    icon: 'list',
                    data: 'home-account-dollbaoTransfer',
                    link: '/member/account-transfer/dollbao',
                    hidden: defaultHidden,
                },*/
                {
                    title: 'shopcash',
                    icon: 'list',
                    data: 'home-account-shopcash',
                    link: '/member/account-feedback/shopcash',
                    hidden: defaultHidden,
                },
                /*
                {
                    title: 'shopcashTransfer',
                    icon: 'list',
                    data: 'home-account-shopcashTransfer',
                    link: '/member/account-transfer/shopcash',
                    hidden: defaultHidden,
                },*/
                /*
                {
                    title: 'couponTicketList',
                    icon: 'list',
                    data: 'home-account-couponTicketList',
                    link: '/shop/coupon/ticket',
                    hidden: defaultHidden,
                },
                {
                    title: 'couponTransfer',
                    icon: 'list',
                    data: 'home-account-couponTransfer',
                    link: '/member/coupon-transfer',
                    hidden: defaultHidden,
                }*/
            ]
        },



        //系統選項
    	{
            title: 'option',
            icon: 'shopping-bag-outline',
            data : 'home-option',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
            	{
                    title: 'news_info',
                    icon: 'grid-outline',
                    data: 'home-option-news_info',
                    link: '/content/option/news_info',
                    hidden: defaultHidden,
                },
                {
                    title: 'member_relation',
                    icon: 'grid-outline',
                    data: 'home-option-member_relation',
                    link: '/content/option/member_relation',
                    hidden: defaultHidden,
                },
                {
                    title: 'baby_relation',
                    icon: 'grid-outline',
                    data: 'home-option-baby_relation',
                    link: '/content/option/baby_relation',
                    hidden: defaultHidden,
                }
            ]
        },


   		//系統管理
        {
            title: 'admin',
            icon: 'settings-outline',
            data : 'home-admin',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'adminList',
                    icon: 'list',
                    data: 'home-admin-adminList',
                    link: '/admin/user',
                    hidden: defaultHidden,
                },
                {
                    title: 'adminGroup',
                    icon: 'person-delete-outline',
                    data: 'home-admin-adminGroup',
                    link: '/admin/group',
                    hidden: defaultHidden,
                },
				{
                    title: 'adminDepartmentList',
                    icon: 'list',
                    data: 'home-admin-adminDepartmentList',
                    link: '/admin/department',
                    hidden: defaultHidden,
                },

                {
                    title: 'employeeList',
                    icon: 'list',
                    data: 'home-admin-employeeList',
                    link: '/admin/employee',
                    hidden: defaultHidden,
                },

                {
                    title: 'importLogList',
                    icon: 'activity-outline',
                    data: 'home-admin-importLogList',
                    link: '/admin/import/log',
                    hidden: defaultHidden,
                },
                {
                    title: 'logList',
                    icon: 'activity-outline',
                    data: 'home-admin-logList',
                    link: '/admin/log',
                    hidden: defaultHidden,
                },
                /*
                {
                    title: 'apiLogList',
                    icon: 'activity-outline',
                    data: 'home-admin-apiLogList',
                    link: '/admin/apilog',
                    hidden: defaultHidden,
                },
                {
                    title: 'parameterList',
                    icon: 'activity-outline',
                    data: 'home-admin-parameterList',
                    link: '/admin/parameter',
                    hidden: defaultHidden,
                },*/
            ]
        }

];
